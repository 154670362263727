// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

@import '_user-variables.scss';

p {
  font-size: 0.8333333333rem;
}

// create donor styles
.nav-pills .custom-nav-link {
  position: relative;
  color: var(--falcon-gray-1100);
  padding-left: 32px;
  font-weight: 600;
  margin-bottom: 25px;
}

.nav-pills .custom-nav-link:last-of-type {
  margin-bottom: 0;
}

.nav-pills .custom-nav-link::before {
  position: absolute;
  content: '';
  top: 12px;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 3px solid var(--falcon-gray-300);
  background: var(--falcon-white);
}

.dark .nav-pills .custom-nav-link::before {
  background: var(--falcon-gray-100);
}

.nav-pills .custom-nav-link.active::before {
  background: var(--falcon-primary);
  border-color: var(--falcon-primary);
}

.nav-pills .custom-nav-link.active.current::before {
  border-color: var(--falcon-gray-300);
}

.nav-pills .custom-nav-link.active {
  background-color: transparent !important;
  color: var(--falcon-primary) !important;
}

.nav-pills .custom-nav-link::after {
  position: absolute;
  content: '';
  border-left: 2px dashed #d8e2ee;
  height: 50px;
  left: 6.5px;
  top: 28px;
}

.nav-pills .custom-nav-link.active::after {
  border-color: #2a7be47a;
}

.nav-pills .custom-nav-link:last-of-type::after {
  content: unset;
}

// donor profile style
.nav-link.donor-profile-nav {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.nav-pills .nav-link.donor-profile-nav.active {
  background-color: var(--falcon-primary);
  border: 1px solid var(--falcon-primary);
  color: var(--falcon-white);
  padding-left: 38px;
  position: relative;
}

.nav-pills .nav-link.donor-profile-nav.active::before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  background-color: var(--falcon-primary);
  border-radius: 50%;
  left: 14px;
  top: 16px;
}

.donor-carousel .carousel-indicators {
  left: 1rem;
  right: initial;
  margin-bottom: unset;
  margin-left: auto;
  margin-right: auto;
  bottom: 0.5rem;
}

.donor-carousel .carousel-indicators [data-bs-target] {
  background-color: var(--falcon-gray-400);
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.donor-carousel .carousel-indicators .active {
  background-color: var(--falcon-primary);
}

.donor-carousel-counter {
  bottom: 1rem;
  right: 1rem;
  position: absolute;
}

.schedule-list {
  padding: 10px;
  border: 1px solid var(--falcon-primary);
  border-radius: 25px;
  color: var(--falcon-primary);
  margin-right: 14px;
  margin-bottom: 14px;
  text-align: center;
}

.selected,
.schedule-list:hover {
  background-color: var(--falcon-primary);
  color: var(--falcon-gray-100) !important;
  cursor: pointer;
}

@media (min-width: 768px) {
  .schedule-list {
    padding: 14px;
  }
}

// wr raise top nav style
.wr-raise-top-nav {
  border-bottom: 1px solid var(--falcon-gray-400) !important;
  box-shadow: 0px 0px 0px var(--falcon-gray-white) !important;

  & a {
    padding: 13px 0 11px;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    color: var(--falcon-gray-600);
    font-weight: 500;
    transition: all 0.4ms;
  }

  & .active,
  & a:hover {
    text-decoration: none;
    border-color: var(--falcon-primary);
    color: var(--falcon-gray-1100);
  }

  & .disable {
    color: gray;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.navLinkDisable {
  pointer-events: none !important;
  cursor: not-allowed !important;

  &.nav-item .nav-link {
    color: #c7c8ca !important;
  }
}

.w-33 {
  width: 33.33%;
}

.donor-info-title {
  width: 100px;
}

.table-input-search-width {
  width: 100%;
}

@media (min-width: 768px) {
  .w-md-33 {
    width: 33.33% !important;
  }

  .donor-info-title {
    width: 200px;
  }

  .table-input-search-width {
    width: 13.75rem;
  }
}

.hover-border-primary:hover {
  border-color: var(--falcon-primary) !important;
}

@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand+SC&display=swap');

.wr-fe-logo {
  max-height: 35px;
}

.hover-bg-primary:hover {
  background-color: var(--falcon-primary) !important;
}

.wr-fe-nav {
  box-shadow: 0px 7px 14px rgba(65, 69, 88, 0.1),
    0px 3px 6px rgba(0, 0, 0, 0.07);
  background-color: #fff;
}

.we-card-shadow {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2) !important;
}

.wr-custom-label {
  position: absolute;
  z-index: 1;
  top: -9px;
  left: 13px;
  padding: 0 4px;
  background-color: var(--falcon-gray-white);
  font-weight: 500;
}

.left-26 {
  left: 22px;
}

.wr-custom-donation-btn:hover,
.wr-custom-donation-btn.active {
  background-color: var(--falcon-primary) !important;
  color: var(--falcon-gray-white) !important;
}

.wr-custom-donation-btn:hover {
  filter: brightness(0.85);
}

.wr-donation-amount {
  background: rgba(70, 142, 239, 0.1) !important;
  color: var(--falcon-primary) !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  padding: 14px !important;
  border-radius: 8px !important;
}

.h-52,
.wr-donation-amount-currency .react-select__control {
  height: 52px;
}

.wr-donation-amount:hover,
.wr-donation-amount.active {
  background-color: var(--falcon-primary) !important;
  color: var(--falcon-gray-white) !important;
}

.font-patrick {
  font-family: 'Patrick Hand SC', cursive !important;
}

.donation-info-edit-icon {
  border: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translate(-6%, -50%);
}

.card-input-icon {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translate(0%, -50%);
}

.center-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.w-215px {
  width: 100%;
}

.wr-top-nav-btn {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  width: 110px;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.wr-top-nav-item {
  position: relative;
  padding: 0 0.5rem;
  display: block;
  height: 30px;
  width: 30px;
  background: var(--falcon-gray-300);
  border-radius: 50%;
  font-size: 1rem;
  color: var(--falcon-gray-800);
  display: flex;
  align-items: center;
  justify-content: center;
}

.after-line::after,
.before-line::before {
  transform: translate3d(0, -50%, 0);
  content: '';
  height: 2px;
  width: 130%;
  position: absolute;
  top: 50%;
  background: var(--falcon-gray-300);
}

.after-line::after {
  left: 110%;
  z-index: 1;
}

.before-line::before {
  right: 110%;
}

.active .wr-top-nav-item,
.active-after-line.after-line::after,
.active-before-line.before-line::before {
  background: var(--falcon-primary);
  color: var(--falcon-gray-100);
}

.wr-top-nav-label {
  font-weight: 500;
  color: var(--falcon-gray-600);
}

.active .wr-top-nav-label {
  font-weight: 600;
  color: var(--falcon-gray-1000);
}

// Sponsorship values

.spon_values_current_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -0.5rem;
}

.spon_values_current_list {
  border-bottom: 1px solid #edf2f8;
}

.spon_values_current_list_container {
  max-height: 200px;
  overflow-y: auto;
}

.spon_values_current_list_container > div:last-child > div {
  border-bottom: none;
}

// timeline
.spon_timeline {
  position: relative;
}

.spon_tl_border {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 98%;
  width: 100%;
  border-left: 1px dashed #d8e2ee;
}

.spon_tl_event {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.spon_tl_dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #2a7be4;
  border: 1px solid #edf2f8;
}

.spon_tl_dot_active {
  width: 8px;
  height: 8px;
  border: 3px solid rgba(42, 123, 228, 0.15);
}

.spon_tl_title {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: #4d5969;
  padding-left: 20px;
  margin-bottom: 0;
}

.dFlexRowCenterSpace {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dFlexRowStartSpace {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.form_label_aesterik::after {
  content: '*';
  color: red;
  padding-left: 6px;
}

.spon_types_checked_items > div {
  border-bottom: 1px solid #edf2f8;
  padding: 15px 0;
}

.spon_types_checked_items > div:last-child {
  border-bottom: none;
  padding-bottom: unset;
}

.form-select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3C!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z'/%3E%3C/svg%3E");
}

.amount-content {
  margin-right: 130px;
}

.amount-content-2 {
  margin-right: 80px;
}

.amount-content-2 > div:first-child {
  margin-right: 25px;
}

// .dropdown-menu {
//   position: fixed !important;
// }

@media (min-width: 768px) {
  .wr-top-nav-btn {
    width: 180px;
  }

  .wr-top-nav-item {
    padding: 0 1rem;
    height: 48px;
    width: 48px;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .after-line::after,
  .before-line::before {
    height: 4px;
  }

  .w-215px {
    width: 215px;
  }

  .wr-fe-another-donation-modal {
    min-width: 600px;
  }

  .wr-fe-logo {
    max-height: 50px;
  }
}

@media (max-width: 320px) {
  .col-xxs {
    width: 100% !important;
  }
  .amount-content-2 > div:first-child {
    margin-right: 5px;
  }
}

.v-hidden {
  visibility: hidden;
}

@media (max-width: 1028px) {
  .amount-content {
    margin-right: 30px;
  }
  .amount-content-2 {
    margin-right: 30px;
  }
}

/* utility classes */

.w-144 {
  width: 144px !important;
}

.h-94 {
  height: 94px;
}
.h-48 {
  height: 48px;
}
.gap-25 {
  gap: 25px;
}
.rounded-12 {
  border-radius: 12px !important;
}
.p-24 {
  padding: 24px;
}
.wr-donation-shadow {
  box-shadow: 0 2px 8px 0 #00000033;
}
.wr-donation-shadow-1 {
  box-shadow: 0 2px 7px 0 #0000001c;
}

/* colors */

.wr-donation-text-primary {
  color: map-get($wr-donation-colors, primary) !important;
}
.wr-donation-text-brand-secondary {
  color: map-get(map-get($wr-donation-colors, brand), secondary);
}
.wr-donation-text-grey-400 {
  color: map-get(map-get($wr-donation-colors, grey), 400) !important;
}
.wr-donation-text-grey-500 {
  color: map-get(map-get($wr-donation-colors, grey), 500) !important;
}
.wr-donation-text-grey-700 {
  color: map-get(map-get($wr-donation-colors, grey), 700) !important;
}
.wr-donation-text-grey-900 {
  color: map-get(map-get($wr-donation-colors, grey), 900) !important;
}
.wr-donation-text-grey-1000 {
  color: map-get(map-get($wr-donation-colors, grey), 1000) !important;
}
.wr-donation-text-grey-800 {
  color: map-get(map-get($wr-donation-colors, grey), 800) !important;
}
.wr-donation-text-yellow-300 {
  color: map-get(map-get($wr-donation-colors, yellow), 300) !important;
}

.border-wr-donation-grey-200 {
  border-color: map-get(map-get($wr-donation-colors, grey), 200) !important;
}

.bg-wr-donation-primary {
  background-color: map-get($wr-donation-colors, primary) !important;
}
.bg-wr-donation-blue-200 {
  background-color: map-get(map-get($wr-donation-colors, blue), 200) !important;
}
.bg-wr-donation-secondary {
  background-color: map-get($wr-donation-colors, secondary) !important;
}
.bg-wr-donation-grey-0 {
  background-color: map-get(map-get($wr-donation-colors, grey), 0) !important;
}
.bg-wr-donation-grey-50 {
  background-color: map-get(map-get($wr-donation-colors, grey), 50) !important;
}
.bg-wr-donation-grey-100 {
  background-color: map-get(map-get($wr-donation-colors, grey), 100) !important;
}
.bg-wr-donation-grey-120 {
  background-color: map-get(map-get($wr-donation-colors, grey), 120) !important;
}
.bg-wr-donation-grey-150 {
  background-color: map-get(map-get($wr-donation-colors, grey), 150) !important;
}
.bg-wr-donation-grey-600 {
  background-color: map-get(map-get($wr-donation-colors, grey), 600) !important;
}
.bg-wr-donation-success-500 {
  background-color: map-get(
    map-get($wr-donation-colors, success),
    500
  ) !important;
}

.bg-wr-donation-yellow-500 {
  background-color: map-get(
    map-get($wr-donation-colors, yellow),
    500
  ) !important;
}

.border-blue-100 {
  border-color: map-get(map-get($wr-donation-colors, blue), 100) !important;
}

/* TYPOGRAPHY */

.wr-donation-subtitle1 {
  font-family: 'Public Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.wr-donation-body1 {
  font-family: 'Public Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.wr-donation-body2 {
  font-family: 'Public Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}
.wr-donation-body3 {
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
}
.wr-donation-body4 {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.7px;
}
.wr-donation-body5 {
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.16px;
}
.wr-donation-body6 {
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
}
.wr-donation-body7 {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.47px;
}
.wr-donation-header2 {
  font-family: 'Oswald', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 47.42px;
}
.wr-donation-header3 {
  font-family: 'Public Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}
.wr-donation-header4 {
  font-family: Oswald, sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 18.47px;
}

.wr-donation-helper-text {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

/* END TYPOGRAPHY */

.wr-donation-privacy-policy {
  font-family: 'Open Sans', serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
}

.wr-donation-btn {
  border: none !important;
  outline: none !important ;
}

.wr-donation-cart-btn {
  font-family: Arial, serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  width: 192px;
  height: 54px;
  gap: 10px;
}

.wr-donation-thanks-container {
  margin: 100px auto auto;
  width: 90%;
  max-width: 675px;
}

.wr-donation-thanks-card {
  padding: 30px 20px 20px 20px;
  border: 1px solid #d8e2ee !important;
  box-shadow: none !important;
}

.wr-donation-payment-container {
  margin: 80px auto auto;
  width: 90%;
  max-width: 728px;
}

.wr-donation-payment-method-header {
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: map-get(map-get($wr-donation-colors, grey), 700);
  margin-bottom: 24px;
}

.wr-donation-donor-form-header {
  border-bottom: 3px solid map-get(map-get($wr-donation-colors, yellow), 500);
}

.wr-donation-cart-header {
  border-bottom: 3px solid map-get($wr-donation-colors, primay);
}

.wr-donation-summary-card {
  box-shadow: 0 2px 7px 0 #0000001c;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
}

.wr-donation-payment-type-checkbox {
  & > input[type='checkbox']:checked {
    background-color: map-get($wr-donation-colors, primary);
    border: none;
  }
}

.wr-donation-border-primary {
  border: 1px solid map-get($wr-donation-colors, primary) !important;
}

.wr-donation-border-b-yellow {
  border-bottom: 1px solid map-get(map-get($wr-donation-colors, yellow), 500);
}

.custom-switch-size .form-check-input {
  width: 50px !important;
  height: 25px !important;
}

.hover\:bg-wr-donation-yellow:hover {
  background-color: map-get(
    map-get($wr-donation-colors, yellow),
    300
  ) !important;
}
